import { decorate, observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class CustomerStore {
    customerFullName = "";
    customerEmail = "";
    customerCountryCode = "";
    customerCity = "";
    customerArea = "";
    customerState = "";
    customerPostalCode = "";
    customerStreetAddress = "";
    customerMobileNumber = "";
    customerAreaCode = "";
    customerResidentialNumber = "";
    customerNationalId = "";
    customerDateOfBirth = "";
    userAgeConsent = "";
    tncAgreed = false;
    tnc06 = false;
    tnc07 = false;
    tnc08 = false;
    isValidatedForm = false;
    occupation = "";
    incomeSource = "";

    updateCustomerDetailAccount = customerDetail => {
        this.customerFullName = customerDetail.customerFullName;
        this.customerNationalId = customerDetail.customerNationalId;
        this.customerDateOfBirth = customerDetail.customerDateOfBirth;
        this.customerMobileNumber = customerDetail.customerMobileNumber;
        this.customerAreaCode = customerDetail.customerAreaCode;
        this.customerResidentialNumber = customerDetail.customerResidentialNumber;
        this.customerEmail = customerDetail.customerEmail;
        this.tncAgreed = customerDetail.tncAgreed;
    }

    updateCustomerDetailReviewOrder = customerDetail => {
        this.customerCity = customerDetail.customerCity;
        this.customerArea = customerDetail.customerArea;
        this.customerStreetAddress = customerDetail.customerStreetAddress;
        this.occupation = customerDetail.occupation;
        this.incomeSource = customerDetail.incomeSource;
        this.customerPostalCode = customerDetail.customerPostalCode;    
    };
}

decorate(CustomerStore, {
    customerFullName: [persist, observable],
    customerEmail: [persist, observable],
    customerCountryCode: [persist, observable],
    customerCity: [persist, observable],
    customerArea: [persist, observable],
    customerState: [persist, observable],
    customerPostalCode: [persist, observable],
    customerStreetAddress: [persist, observable],
    customerMobileNumber: [persist, observable],
    customerAreaCode: [persist, observable],
    customerResidentialNumber: [persist, observable],
    customerDateOfBirth: [persist, observable],
    customerNationalId: [persist, observable],
    userAgeConsent: [persist, observable],
    tncAgreed: [persist, observable],
    occupation: [persist, observable],
    incomeSource: [persist, observable],
    isValidatedForm: [persist, observable],
    updateCustomerDetail: action,
});
var customerStore = new CustomerStore();
export default customerStore;

if (typeof window !== "undefined") {
    const hydrate = create({
        storage: localStorage,
        jsonify: true,
    });
    hydrate("customerStore", customerStore);
}
